<template>
  <div v-if="$canAndRulesPass(user, $permissions.USER.DEPUTIES_SEE_IN_MENU)">
    <page-heading
      :heading="pageTitle"
    >
      <span slot="help"></span>
      <span slot="right">
        <el-button
          v-if="$canAndRulesPass(user, $permissions.USER.DEPUTIES_CREATE)"
          style="margin-left:10px"
          type="primary"
          size="small"
          icon="fas fa-plus"
          @click="modalShow"
        >
            Выдать доступ
          </el-button>
      </span>
    </page-heading>


    <el-card>
      <search-input
        :placeholder="$companyConfiguration.i18n.t('deputy', 'search_field_placeholder', 'Поиск по ФИО и личному номеру')"
        style="width: 480px"
        v-model="search"
        @change="loadDeputies"
      >
      </search-input>

      <el-table
        style="width: 100%; margin-top: 20px"
        :data="deputies"
        border
        empty-text="Нет делегатов"
        v-loading="loading"
      >
        <component
          v-for="(columnConfig, index) in columnsConfigs"
          :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseTableColumn"
          :key="index"
          :column-config="columnConfig"
        >
        </component>

        <el-table-column width="50" align="right">
          <template v-slot="scope">
            <el-popconfirm
              v-if="$canAndRulesPass(scope.row, $permissions.USER.DEPUTIES_DELETE)"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить доступ?"
              @confirm="deleteDeputy(scope.row)"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                round
                icon="far fa-trash-alt"
              ></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>


      <paginator
        :paginator.sync="paginator"
        @page-changed="loadDeputies"
      ></paginator>

    </el-card>

    <deputy-create-dialog
      ref="deputyCreateDialog"
      @deputy-saved="loadDeputies"
    ></deputy-create-dialog>
  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import ChiefsEditComponent from "@/components/user/ChiefsEditComponent.vue";
import {mapGetters} from "vuex";
import CardEditDialog from "@/components/cards/CardEditDialog.vue";
import DeputyCreateDialog from "@/components/user/deputies/DeputyCreateDialog.vue";
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator.vue";
import SearchInput from "@/components/filters/SearchInput.vue";

export default {
  name: 'Chiefs',
  components: {SearchInput, Paginator, DeputyCreateDialog, CardEditDialog, ChiefsEditComponent, PageHeading},
  mixins:[paginator],

  computed: {
    ...mapGetters(['user']),
    baseTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseTableColumn`);
    },
    pageTitle(){
      return document.title;
    },
  },
  data() {
    return {
      loading: false,
      search: null,

      deputies: [],
      columnsConfigs: [],
    }
  },
  beforeMount() {
    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.userDeputy.getDeputiesPageColumns()
    );
  },
  mounted() {
    this.loadDeputies();
  },
  methods: {

    modalShow(){
      this.$refs.deputyCreateDialog.open();
    },

    loadDeputies(){
      this.loading = true;

      this.$api.user.searchDeputies({
        search: this.search,
        expand: 'permissions, deputyForUser, deputyUser',

        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.deputies = data.deputies;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    deleteDeputy(deputy){
      this.loading = true;

      this.$api.user.deleteDeputy({
        id: deputy.id,
      })
        .finally(() => {
          this.loadDeputies();
        })
    }
  }
}

</script>


<style lang="scss">


</style>
