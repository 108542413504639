<template>
  <el-dialog
    :visible.sync="show"
    width="30%"
    title="Выдача доступа"
    :close-on-click-modal="false"
  >

    <el-form
      ref="deputyCreateForm"
      size="small"
      label-position="top"
      :model="deputy"
      :rules="rules"
    >
      <el-form-item
        :label="$companyConfiguration.i18n.t('deputy', 'deputy_for_user_field_name', 'Кого необходимо заместить')"
        prop="deputyForId"
      >
        <div style="display: flex; justify-content: space-between;">
          <div>
            {{deputy.deputyForUser ? deputy.deputyForUser.full_name : ''}}
          </div>
          <add-users-dialog
            single-select
            add-button-text="Нажмите на строку для выбора"
            @users-selected="userSelected('deputyFor', $event)"
          >
            <el-button
              slot="reference"
              class="table-action-button"
              size="small"
              icon="fas fa-person-booth"
            >
              Выбрать
            </el-button>
          </add-users-dialog>
        </div>
      </el-form-item>

      <el-form-item
        :label="$companyConfiguration.i18n.t('deputy', 'deputy_user_field_name', 'Заместитель')"
        prop="deputyId"
      >

        <div style="display: flex; justify-content: space-between;">
          <div>{{deputy.deputyUser ? deputy.deputyUser.full_name : ''}}</div>

          <add-users-dialog
            single-select
            add-button-text="Нажмите на строку для выбора"
            @users-selected="userSelected('deputy', $event)"
          >
            <el-button
              slot="reference"
              class="table-action-button"
              size="small"
              icon="fas fa-person-booth"
            >
              Выбрать
            </el-button>
          </add-users-dialog>
        </div>
      </el-form-item>


      <el-form-item label="Период доступа" prop="dates">
        <div style="display: flex; justify-content: space-between">
          <el-date-picker
            v-model="deputy.date_start"
            style="width:240px"
            placeholder="Выберите дату начала"
            :picker-options="{firstDayOfWeek: 1}"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
          >
          </el-date-picker>
          -
          <el-date-picker
            v-model="deputy.date_end"
            style="width:240px"
            placeholder="Выберите дату окончания"
            :picker-options="{firstDayOfWeek: 1}"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
          >
          </el-date-picker>
        </div>
      </el-form-item>


    </el-form>


    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="createDeputy"
      >
        Сохранить
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Container, Draggable} from 'vue-dndrop';
import StageItem from "@/components/cards/CardStagesBlock/StageItem";
import UsersTable from "@/components/user/UsersTable";
import CardCreateTemplates from "@/components/cards/CardCreateTemplates.vue";
import {mapGetters} from "vuex";
import PeriodSelector from "@/components/PeriodSelector.vue";
import CardEditForm from "@/components/cards/CardEditForm.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";

export default {
  name: "deputy-create-dialog",
  components: {AddUsersDialog, UserSelect},

  props: {},

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    const checkDates = (rule, value, callback) => {
      if( this.deputy.date_start && this.deputy.date_end ){
        callback();
      } else {
        callback(new Error('Поле обязательно для заполнения'));
      }
    };
    const checkDeputy = (rule, value, callback) => {
      if( this.deputy.deputyForId !== this.deputy.deputyId ){
        callback();
      } else {
        callback(new Error('Нельзя быть заместителем самому себе'));
      }
    };

    return {
      show: false,
      loading: false,

      deputy: {
        deputyForUser: null,
        deputyForId: null,
        deputyUser: null,
        deputyId: null,
        date_start: null,
        date_end: null,
      },

      rules: {
        deputyForId: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'change'},
          { validator: checkDeputy, trigger: 'input'},
        ],
        deputyId: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'change'},
          { validator: checkDeputy, trigger: 'input'},
        ],
        dates: [
          { validator: checkDates, required: true, trigger: 'change'},
        ],
      },

    }
  },
  mounted() {
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },

    userSelected(type, user){
      this.deputy[type+'User'] = user;
      this.deputy[type+'Id'] = user.id;
    },


    async createDeputy() {
      let success = await this.$refs.deputyCreateForm.validate();
      if( !success ){
        return;
      }

      this.loading = true;
      this.$api.user.createDeputy({
        deputy_for_user_id: this.deputy.deputyForId,
        deputy_user_id: this.deputy.deputyId,
        date_start: this.deputy.date_start,
        date_end: this.deputy.date_end,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
          this.close();
          this.$emit('deputy-saved');
        })
    },

  }
}
</script>

<style scoped lang="scss">
</style>